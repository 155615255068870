// eslint-disable-next-line
import introspectionQueryResultData from '../../../../../graphql.schema.json';

const possibleTypes = {};

// Generate supertype-subtype relationships.
// https://www.apollographql.com/docs/react/data/fragments/#generating-possibletypes-automatically
introspectionQueryResultData.__schema.types.forEach((supertype) => {
  if (supertype.possibleTypes) {
    possibleTypes[supertype.name] = supertype.possibleTypes.map((subtype) => subtype.name);
  }
});

export default possibleTypes;
